import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'
import ReactVideo from "../../../video-player/ReactVideo";


import rfidAssignedProgNewLock from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-program-new-lock.jpg';
import rfidAssignedAddManKeysRfidCreds from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-add-man-keys-creds.jpg';
import rfidAssignedRepManKeysRfidCreds from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-rep-man-keys-creds.jpg';
import rfidAssignedAssignCredWithKeyCredential from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-assign-creds-man-key-rfid-cred.jpg';
import rfidAssignedExpressRegUser from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-express-reg-user.jpg';
import rfidAssignedRemoveUserWithManKey from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-remove-creds-with-man-key.jpg';
import rfidAssignedOperateUserCred from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-operate-user-cred.jpg';
import rfidAssignedOperateManKey from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-operate-man-key.jpg';
import rfidAssignedOperateProgKey from '../../../../images/support/posters/curve/rfid/assigned/rfid-assigned-operate-prog-key.jpg';

import keyAssignedNewLock from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-prog-new-lock.jpg';
import keyAssignedAddManKeys from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-add-man-keys.jpg';
import keyAssignedRepManKeys from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-rep-man-keys.jpg';
import keyAssignedAssUserCodes from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-ass-user-codes.jpg';
import keyAssignedExpRegCredsKeys from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-exp-reg-cred-keys.jpg';
import keyAssignedRemUserCreds from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-remove-user-creds.jpg';
import keyAssignedRepCodeWithCode from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-rep-code-new-code.jpg';
import keyAssignedOperUserCode from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-oper-user-code.jpg';
import keyAssignedOperManKey from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-oper-man-key.jpg';
import keyAssignedOperProgKey from '../../../../images/support/posters/curve/keypad/assigned/key-assigned-oper-prog-key.jpg';
import rfidSharedProgNewLock from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-prog-new-lock.jpg';
import rfidSharedRegManKeysRFID from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-reg-man-keys-rfid-credsjpg.jpg';
import rfidSharedAddManKeysRFID from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-add-man-keys-rfid-credsjpg.jpg';
import rfidSharedRepManKeysRFID from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-rep-man-keys-rfid-credsjpg.jpg';
import rfidSharedOperRFIDCred from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-operate-rfid-cred.jpg';
import rfidSharedOverCreds from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-over-credentials.jpg';
import rfidSharedOverKey from '../../../../images/support/posters/curve/rfid/shared/rfid-shared-over--key.jpg';



import keySharedNewKey from '../../../../images/support/posters/curve/keypad/shared/key-shared-new-lock.jpg';
import keySharedExpRegNewKeys from '../../../../images/support/posters/curve/keypad/shared/key-shared-express-reg-man-keys.jpg';
import keySharedAddManKeys from '../../../../images/support/posters/curve/keypad/shared/key-shared-add-man-keys.jpg';
import keySharedRepManKeys from '../../../../images/support/posters/curve/keypad/shared/key-shared-rep-man-keys.jpg';
import keySharedUserCode from '../../../../images/support/posters/curve/keypad/shared/key-shared-user-code.jpg';
import keySharedOperManKey from '../../../../images/support/posters/curve/keypad/shared/key-shared-oper-man-key.jpg';
import keySharedOperProgKey from '../../../../images/support/posters/curve/keypad/shared/key-shared-oper-prog-key.jpg';




let dataSheetsKeypad = [
  {
    "title": "Curve | Basic & Advanced | Keypad | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6oC6rnPgHA7DlHliVPwkth/35a7588ae22412d2b81153110ca6c64b/DS-D6CKX-D-DEN.pdf",
      "fileName": "DS-D6CKX-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Basic & Advanced | Keypad | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7B79WdhR1pERyUndm5bsqS/6ffa376d85a70d0efadb3c31f306bf63/DS-D6CKX-P-DEN.pdf",
      "fileName": "DS-D6CKX-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesKeypad = [
  {
    "title": "Curve | Basic & Advanced | Keypad & RFID",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/1uJ1aHHWNIGLp3UPHeXRUC/d115189e523d81dbb9f7bec932625f64/IG-D6CA-KR-DEN.pdf",
      "fileName": "IG-D6CA-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesKeypad = [
  {
    "title": "Curve | Basic & Advanced | Keypad | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/77xZ4jY3mmb7LNA77CLDBH/3bd42e7ccbf984a3e27c87bd8d08eda4/PG-D6CKX-D-DEN.pdf",
      "fileName": "PG-D6CKX-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Basic & Advanced | Keypad | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6qeVtYGe1oGu0CQyO03l9Y/7724038354bbf7acaf904c29783d283e/PG-D6CKX-P-DEN.pdf",
      "fileName": "PG-D6CKX-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

let dataSheetsRFID = [
  {
    "title": "Curve | Basic & Advanced | RFID | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2khkyx0C9JLBoCn3GOZgyw/b62bd2a7573b5979f1b3f090906000ac/DS-D6CRX-D-DEN.pdf",
      "fileName": "DS-D6CRX-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Basic & Advanced | RFID | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7f8EsormYFPFAqUkXlMJYt/2ce2cf9d3e1343934e7cf2b5e540fac3/DS-D6CRX-P-DEN.pdf",
      "fileName": "DS-D6CRX-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Curve | Basic & Advanced | RFID & Keypad",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/7yOQwVtaTryHx3RuIj2A3k/6a83ebff471749a4feeb215b4a91f0c0/IG-D6CA-KR-DEN.pdf",
      "fileName": "IG-D6CA-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesRFID = [
  {
    "title": "Curve | Basic & Advanced | RFID | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2jVCD12efIcdGD9tGfDs8p/cfd13f68b3dfc9e118bb94ae7a540e16/PG-D6CRX-D-DEN.pdf",
      "fileName": "PG-D6CRX-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Basic & Advanced | RFID | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5ZGHa1aplnQIlj7p6zaN8B/3469ac09dc54ba0b6c099af7fc32e7ca/PG-D6CRX-P-DEN.pdf",
      "fileName": "PG-D6CRX-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
    if(index === 2) {
      let videoAcc = document.getElementById('Curve-How-To-Videos');
      videoAcc.addEventListener('click', videoClicker);
    }
  };

  function videoClicker () {
    alert('hi');
  }

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };


  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Curve <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Curve <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/curve-keypad-ba.png'}
                  loading={'lazy'}
                  width={377}
                  height={600}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Curve Electronic Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Units"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Curve How To Videos">
                    <Accordion clsName={'next-support'}>
                      <div label={'Assigned Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={keyAssignedNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/gwt1wsGdpEibX2t5xlNzkA/fknxHtb99E-LtdYgCOndZg/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedAddManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/z9uyy4MdJ0SHJw3GsRjTqA/-02ZCyTMgEmqu9bQtZ7tRg/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-02_How%20to%20Add%20Manager%20Keys.mp4'}
                            />
                            <p>How to add manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedRepManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/22g1S8-K10qWxWDnZFKmZg/g6msNts1dkCHGzRDQibL4Q/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-03_How%20to%20Replace%20Manager%20Keys.mp4'}
                            />
                            <p>How to replace manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedAssUserCodes}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/8yLb5ndZDEectLHSo45PSw/bybh5bpVEUuB5Xp5r808cA/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-04_How%20to%20Assign%20User%20Codes.mp4'}
                            />
                            <p>How to assign user codes</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedExpRegCredsKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/krIS58SSFECPrd1Tx-ZdnQ/dLGFifNHcUKqD4lmJ8bZig/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-05_How%20to%20Express%20Register%20User%20Credentials%20and%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register user credentials and manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedRemUserCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/Irvi5Ra_qEu4FbLKU0hq8A/c7gjUH7FSU6cg_SwPh-FmQ/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-06_How%20to%20Remove%20User%20Credentials.mp4'}
                            />
                            <p>How to remove user credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedRepCodeWithCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/h2laY6g4VU-RqN2kBhWazw/RrUwmXQmyUuB69WrnnEZrw/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Assigned%20Use-07_How%20to%20Replace%20an%20Assigned%20User%20Code%20with%20a%20New%20User%20Code.mp4'}
                            />
                            <p>How to replace an assigned user code with a new user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedOperUserCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/G44wKmYcO0Kj71W9AhJ17w/M3HKch-wAkymBh6W7UTKpA/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_AssignedUse-08_How%20to%20Operate%20with%20a%20User%20Code.mp4'}
                            />
                            <p>How to operate with a user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedOperManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/aNaQ_hLrpEqgyKXvvOzl3g/heUwFh7iE0KvWdxHN2aITQ/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_AssignedUse-09_How%20to%20Operate%20with%20a%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keyAssignedOperProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/SQ0As9wDVEyGmyOgI14YIw/TPXwlj0mhk6cBtDXkFb2tw/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_AssignedUse-10_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>

                        </div>
                      </div>
                      <div label={'Shared Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={keySharedNewKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/wRc9VEhxC067oi-H_B8jsw/l0AhS7Mh8EirZAJdmNy9RQ/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Shared%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keySharedExpRegNewKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/g2zSa5TCy0CdJpJL4mch-g/oK9S0_tm6EiA11VYszvobQ/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Shared%20Use-02_How%20to%20Express%20Register%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keySharedAddManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/MBeuhpPkE0WWC15JdgP9QA/Y1PSAqbhjkqUDJlMQPKqRw/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Shared%20Use-03_How%20to%20Add%20Manager%20Keys.mp4'}
                            />
                            <p>How to add manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keySharedRepManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/opdGXUe3Bkmi2b-IHhu7dA/qXKssUiDw0ahckORUxnl-Q/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_Shared%20Use-04_How%20to%20Replace%20Manager%20Keys.mp4'}
                            />
                            <p>How to replace manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keySharedUserCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/SRMZeOR-O0STcqS9NaK87g/y5a96qXiNEqqJuSRdN704w/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_SharedUse-05_How%20to%20Operate%20with%20a%20User%20Code.mp4'}
                            />
                            <p>How to operate with a user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keySharedOperManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/UK11V1I2EEOcGboZcfNSow/oFa4-wnIG0yr1qM_oPrHVw/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_SharedUse-06_How%20to%20Operate%20with%20a%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keySharedOperProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/t7azLy-03km5XRE7r_plNA/ftIcNDkZFUCM-nk_gLKhYA/Widescreen720p/6G%20Curve%20Keypad_BasicAdv_SharedUse-07_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                        </div>
                      </div>
                    </Accordion>

                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className={"tab-content "+getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/curve-rfid-ba.png'}
                  loading={'lazy'}
                  width={377}
                  height={600}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Curve Electronic Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Units"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Curve How To Videos">
                    <Accordion clsName={'next-support'}>
                      <div label={'Assigned Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedProgNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/xGtYLP1U1kaFVK_FZ7shLQ/jvBs9w_I2Uiui1ro_khPcA/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedAddManKeysRfidCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/am9hLQLY1ECQ4hTnwwko_w/bcxn3VlKqkiQaYbHP8h0dg/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-02_How%20to%20Add%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to add manager keys/rfid credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedRepManKeysRfidCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ndryllx7KUigWbXL_oTA9w/NO6lxQ1_t02xG0KuZrTD5A/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-03_How%20to%20Replace%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to replace manager keys/rfid credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedAssignCredWithKeyCredential}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/XcaVi0XwgEmfhk3V-ag3Pg/JfqHa8o400ymx0HBGujlxw/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-04_How%20to%20Assign%20User%20Credentials%20with%20a%20Manager%20Key-RFID%20Credential.mp4'}
                            />
                            <p>How to assign user credentials with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedExpressRegUser}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/dKcOax7MAE-V-8mUPksNlQ/zp-e1mcFQUKOeJCGT8eKag/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-05_How%20to%20Express%20Register%20User%20and%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to express register user and manager keys/rfid credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedRemoveUserWithManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/9cXCIhKOkkOU2zs3RXoIbg/qX59kmMwEk-L_Pne0TrToA/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-06_How%20to%20Remove%20User%20Credentials%20with%20a%20Manager%20Key-RFID%20Credential.mp4'}
                            />
                            <p>How to remove user credentials with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedOperateUserCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/nXzh07kxEkWsR9_zIAkelQ/SKlGq1DcLUqV8DyF-ccFsQ/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-07_How%20to%20Operate%20with%20a%20User%20RFID%20Credential.mp4'}
                            />
                            <p>How to operate with a user RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedOperateManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/pUNmcaG9zEml-5l4oZYmEg/_8FBdX5OSEy8jjXawsoAeA/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-08_How%20to%20Operate%20with%20a%20Manager%20Key-RFID%20Credential.mp4'}
                            />
                            <p>How to override with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidAssignedOperateProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/-e1oVkfEeUmMNW5DfDWmWA/JW3Ly6-u1U2hCk43yAj9lQ/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Assigned%20Use-09_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                        </div>
                      </div>
                      <div label={'Shared Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={rfidSharedProgNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/1OEn0NMqIUqwYO_rsY-Lqw/oQoSOCx1CE2DwtSNhZsxSA/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Shared%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidSharedRegManKeysRFID}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/RzPJIQDKsU2CTRjUvQl8sw/aOfntCFAxkC0z4hUyJHuSQ/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Shared%20Use-02_How%20to%20Express%20Register%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to express register manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidSharedAddManKeysRFID}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/2Uqsq7cQD0O3G0UtVMJlbA/gcrP5XbG3UKgPSQyVDZnkw/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Shared%20Use-03_How%20to%20Add%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to add manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidSharedRepManKeysRFID}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/cd4GRBSsqEGgiPyBwNicaA/F9y3jp-oeUeaakQuBrhZDw/Widescreen720p/6G%20Curve%20RFID_BasicAdv_Shared%20Use-04_How%20to%20Replace%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to replace manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidSharedOperRFIDCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/aNe4CAZ_x0CgX9Pj5JLlhQ/PnbX8BFSAUe7w81raTzoQw/Widescreen720p/6G%20Curve%20RFID_BasicAdv_SharedUse-05_How%20to%20Operate%20with%20a%20User%20RFID%20Credential.mp4'}
                            />
                            <p>How to operate with a user RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidSharedOverCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/uj-gfKAg1kitsIXlT0pogQ/d5cBPukWqU-q6ztS8-w4eQ/Widescreen720p/6G%20Curve%20RFID_BasicAdv_SharedUse-06_How%20to%20Override%20with%20a%20Manager%20Key-RFID%20Credential.mp4'}
                            />
                            <p>How to override with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={rfidSharedOverKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/WaDn-Vppu0S1O8TNq7FWhA/TSM537FkI0C0cfwEen_8nw/Widescreen720p/6G%20Curve%20RFID_BasicAdv_SharedUse-07_How%20to%20Override%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                        </div>
                      </div>
                    </Accordion>

                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;